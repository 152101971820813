import React from "react";
import Grid from "@mui/material/Grid2";

function Header({activeStep = 0}) {
    return (
        <>
            <Grid size={{ xs: 6, sm: 6, md: 6, lg: 3 }}>
                ORDER #
            </Grid>
            <Grid size={{ xs: 2, sm: 2, md: 2, lg: 5 }}>
                ORDER PLACED
            </Grid>
            <Grid size={{ xs: 2, sm: 2, md: 2, lg: 4 }}>
                TOTAL
            </Grid>
        </>
    )
}

export default Header;