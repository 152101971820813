import React from "react";
import Grid from "@mui/material/Grid2";
import {ListItem} from "@mui/material";
import lodash from "lodash";
import {PaymentMethod} from "../../helpers/interfaces";

interface PaymentMethodItemProps {
    paymentMethod?: PaymentMethod,
}

function PaymentMethodItem(paymentMethodItemProps: PaymentMethodItemProps) {
    const {paymentMethod} = paymentMethodItemProps;

    return (
        <>
            <ListItem secondaryAction={<></>}>
                <Grid size={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
                    {lodash.startCase(paymentMethod?.brand) + ' ' + paymentMethod?.last4}
                </Grid>
            </ListItem>
            <ListItem secondaryAction={<></>}>
                <Grid size={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
                    Billing address:<br/>
                    {paymentMethod?.billingAddress?.name}<br/>
                    {paymentMethod?.billingAddress?.line1 + ' ' + paymentMethod?.billingAddress?.line2}<br/>
                    {paymentMethod?.billingAddress?.city + ', ' + paymentMethod?.billingAddress?.state + ' ' + paymentMethod?.billingAddress?.postalCode}<br/>
                    {paymentMethod?.billingAddress?.country}
                </Grid>
            </ListItem>
        </>
    )
}

export default PaymentMethodItem;