import React, {Fragment, JSX, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import {useAuthMutation} from "../../services/identityService";
import {ENV_DEVELOPMENT} from "../../helpers/globalConst";
import {SIGN_IN} from "../../helpers/routes";

interface Props {
    children: JSX.Element
}

function ProtectedRoute({children}: Props) {
    const navigate = useNavigate();
    const [auth, {isLoading, isSuccess, isError}] = useAuthMutation();
    const devEnv = useRef(false);

    useEffect(() => {
        const isAuth = () => {
            auth({
                userAccessToken: localStorage.getItem('accessToken'),
                userRefreshToken: localStorage.getItem('refreshToken')
            });
            // To avoid to call auth twice in dev env because of React.Strict
            if (process.env.NODE_ENV === ENV_DEVELOPMENT) devEnv.current = true;
        }

        if (!devEnv.current) isAuth();
    }, [navigate, auth]);

    if (isError) {
        navigate(SIGN_IN, {replace: true});
        navigate(0);
    }

    return (
        <Fragment>
            {
                isSuccess ? children : (isLoading ? "" : null) // TODO isLoading
            }
        </Fragment>
    );
}

export default ProtectedRoute;