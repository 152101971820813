import React from "react";
import Grid from "@mui/material/Grid2";
import CheckoutStepper from "../../particles/CheckoutStepper";

function Stepper({activeStep = 0}) {
    return (
        <Grid container sx={{mt: -5, mb: 2}}>
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6 }} offset={{md: 3, lg: 3}} sx={{background: "white", p: 2, borderRadius: 10}}>
                <CheckoutStepper activeStep={activeStep}/>
            </Grid>
            <Grid size={{ md: 12, lg: 12 }}></Grid>
        </Grid>
    )
}

export default Stepper;