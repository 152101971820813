import React from "react";
import Grid from "@mui/material/Grid2";

function Header({activeStep = 0}) {
    return (
        <>
            <Grid size={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
                DESCRIPTION
            </Grid>
        </>
    )
}

export default Header;