import * as React from "react";
import {useAppSelector} from "../../app/hooks";
import {useGetOrdersQuery} from "../../services/orderService";
import {OrderReq} from "../../helpers/interfaces";
import Grid from "@mui/material/Grid2";
import {Card, CardContent, Container, Divider, List, ListItem} from "@mui/material";
import Header from "./header";
import Item from "./item";
import Loading from "../../components/Loading";
import Typography from "@mui/material/Typography";
import lodash from "lodash";

function MyAccount() {
    const loggedInIdentityState = useAppSelector((state) => state.root.identityReducer.loggedInIdentity);
    const {data: maybeOrders, isLoading: ordersLoading} = useGetOrdersQuery({
        customerId: loggedInIdentityState.id,
        page: 1,
        size: 50
    } as OrderReq);

    const orders = lodash.orderBy(maybeOrders || [], ['id'], ['desc']);
    const haveOrders = !!orders.length;

    return (
        <>
            <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid size={{xs: 12, sm: 12, md: 8, lg: 8}} offset={{md: 2, lg: 2}}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%'}}>
                            {!haveOrders && <CardContent>No orders</CardContent>}
                            {haveOrders && <CardContent>
                                <Typography component='h1' variant='h5'>
                                    My Orders
                                </Typography>
                                <List>
                                    <ListItem secondaryAction={<></>}>
                                        <Header/>
                                    </ListItem>
                                    <Divider/>
                                    {orders.map((order, idx) => (
                                        <Item key={idx} idx={idx} order={order}/>
                                    ))}
                                </List>
                            </CardContent>}
                        </Card>
                    </Container>
                </Grid>
            </Grid>
            <Loading show={ordersLoading}/>
        </>
    );
}

export default MyAccount;