import React from "react";
import Grid from "@mui/material/Grid2";

function Header({activeStep = 0}) {
    return (
        <>
            <Grid size={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
                <strong>Product</strong>
            </Grid>
            <Grid size={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                <strong>Qty</strong>
            </Grid>
            <Grid size={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                <strong>Price</strong>
            </Grid>
            <Grid size={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                <strong>Subtotal</strong>
            </Grid>
        </>
    )
}

export default Header;