import * as React from "react";
import Grid from '@mui/material/Grid2';
import MagentoCard from "../../components/MagentoCard";
import NetSuiteCard from "../../components/NetSuiteCard";
import ActionList from "../../components/ActionList";


function Home() {
    return (
        <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
            <Grid container size={{ xs: 12, sm: 12, md: 12, lg: 8 }} columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6 }}>
                    <MagentoCard/>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6 }}>
                    <NetSuiteCard/>
                </Grid>
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
                <ActionList/>
            </Grid>
        </Grid>
    );
}

export default Home;