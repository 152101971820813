import React, {FormEvent, useContext, useState} from 'react';
import {Elements, useStripe} from '@stripe/react-stripe-js';
import {PaymentIntent, StripeError} from "@stripe/stripe-js";
import Grid from "@mui/material/Grid2";
import {Card, CardContent, Container, Divider, List, ListItem} from "@mui/material";
import lodash from "lodash";
import NotificationContext from "../../components/NotificationContext";
import Notification from "../../components/Notification";
import {useGetProductsQuery} from "../../services/productService";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {CART, CHECKOUT, STORE} from "../../helpers/routes";
import Action from "./action";
import {getCartItemsTotal, getProductPrice, getTotalItems} from "../../helpers/helper";
import Loading from "../Loading";
import {clearCart} from "../../app/store/cartSlice";
import {usePlaceOrderMutation} from "../../services/orderService";
import {useConfirmPaymentMutation} from "../../services/paymentService";
import Typography from "@mui/material/Typography";
import Header from "./header";
import Item from "./item";
import Total from "./total";
import {CartItem, PlaceOrderProps} from "../../helpers/interfaces";
import {MAIN_PRODUCT_IMAGE_ORDER} from "../../helpers/globalConst";
import PaymentMethodHeader from "./paymentMethodHeader";
import PaymentMethodItem from "./paymentMethodItem";

interface Payment extends PaymentIntent {
    orderId?: number
}

const PlaceOrder = (placeOrderProps: PlaceOrderProps) => {
    const loggedInIdentityState = useAppSelector((state) => state.root.identityReducer.loggedInIdentity);
    const hasLoggedInIdentity = !(lodash.isEmpty(loggedInIdentityState) || lodash.isNil(loggedInIdentityState));
    const dispatch = useAppDispatch();
    const stripe = useStripe();
    const notificationCtx = useContext(NotificationContext);
    const [loading, setLoading] = useState(false);
    const cartContentState = useAppSelector((state) => state.root.cartReducer.cartContent);
    const cartItems = lodash.get(cartContentState, 'cartItems') || [];
    const {data: maybeProducts, isLoading: getProductsLoading} = useGetProductsQuery({});
    const [placeOrder, {isLoading: placeOrderLoading}] = usePlaceOrderMutation();
    const [confirmPayment, {isLoading: confirmPaymentLoading}] = useConfirmPaymentMutation();
    const {setStep, clientSecret, paymentMethod, setOrderId} = placeOrderProps;

    const cartItemProductId = (cartItem: CartItem) => {
        return maybeProducts?.find(product => product.id === cartItem.productId)?.id
    }

    const cartItemDescription = (cartItem: CartItem) => {
        return maybeProducts?.find(product => product.id === cartItem.productId)?.description
    }

    const cartItemImageLocation = (cartItem: CartItem) => {
        return lodash.get(maybeProducts?.find(product => product.id === cartItem.productId)?.productImages?.filter(i => i.order === MAIN_PRODUCT_IMAGE_ORDER), '0.location', '');
    }

    const handleConfirmPayment = (payment: Payment) => {
        confirmPayment(payment)
                .unwrap()
                .then((orderId) => {
                    setOrderId(orderId);
                    setStep(3);
                })
                .catch(
                    (err) => {
                        handleError({message: err} as StripeError)
                    });
    }

    const handleError = (error: StripeError) => {
        setLoading(false);
        if (!lodash.isUndefined(error.message)) notificationCtx.showNotification(error.message, 'error');
    }

    const handleBackToPaymentDetails = () => {
        setStep(1);
    }

    const combinedLoading = !stripe || loading || getProductsLoading || placeOrderLoading || confirmPaymentLoading;

    if (lodash.isEmpty(clientSecret)) {
        handleError({message: 'Payment details error. Please try again!'} as StripeError);
        handleBackToPaymentDetails();
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        e.preventDefault();

        if (!stripe) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        if (!hasLoggedInIdentity) return;

        setLoading(true);

        placeOrder({})
            .unwrap()
            .then(
                async (order) => {
                    const protocol = window.location.protocol;
                    const domain = window.location.hostname;
                    const port = window.location.port;
                    const {error, paymentIntent} = await stripe.confirmPayment({
                        clientSecret: clientSecret,
                        confirmParams: {
                            return_url: `${protocol}//${domain}:${port ? port : ''}${STORE + CART + CHECKOUT}`
                        },
                        redirect: "if_required"
                    });

                    dispatch(clearCart());

                    if (error) {
                        // This point will only be reached if there is an immediate error when
                        // confirming the payment. Show error to your customer.
                        setLoading(false);
                        handleError(error);
                    } else {
                        const payment: Payment = {
                            orderId: order.id,
                            ...paymentIntent
                        }
                        handleConfirmPayment(payment);
                        // Your customer will be redirected to your `return_url`. For some payment
                        // methods like iDEAL, your customer will be redirected to an intermediate
                        // site first to authorize the payment, then redirected to the `return_url`.
                    }
                }
            )
            .catch((err) => handleError({message: err} as StripeError))
    };

    return (
        <Elements stripe={stripe}>
            <form onSubmit={handleSubmit}>
                <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                    <Grid size={{ xs: 12, sm: 12, md: 8, lg: 8 }}>
                        <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                            <Card variant="outlined" sx={{height: '100%'}}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom sx={{fontWeight: 'bold'}}>
                                        Review order items
                                    </Typography>
                                    <List sx={{mt: 5}}>
                                        <ListItem secondaryAction={<></>}>
                                            <Header/>
                                        </ListItem>
                                        <Divider/>
                                        {cartItems.map((cartItem, idx) => (
                                            <Item key={idx} idx={idx} cartItem={cartItem}
                                                  productId={cartItemProductId(cartItem)}
                                                  description={cartItemDescription(cartItem)}
                                                  imageLocation={cartItemImageLocation(cartItem)}
                                                  productPrice={getProductPrice(cartItem, maybeProducts)}/>
                                        ))}
                                        <ListItem secondaryAction={<></>}>
                                            <Total cartItemsTotal={getCartItemsTotal(cartItems, maybeProducts)}/>
                                        </ListItem>
                                    </List>
                                    <List sx={{mt: 5}}>
                                        <ListItem secondaryAction={<></>}>
                                            <PaymentMethodHeader/>
                                        </ListItem>
                                        <Divider/>
                                        <PaymentMethodItem paymentMethod={paymentMethod} handleBack={handleBackToPaymentDetails}/>
                                    </List>
                                </CardContent>
                            </Card>
                        </Container>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                        <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                            <Card variant="outlined"
                                  sx={{height: '100%', maxHeight: {lg: 250, md: 250, sm: 250, xs: 250}}}>
                                <CardContent>
                                    <Action totalItems={getTotalItems(cartItems)}
                                            cartItemsTotal={getCartItemsTotal(cartItems, maybeProducts)}
                                            disabled={combinedLoading} handleBack={handleBackToPaymentDetails}/>
                                </CardContent>
                            </Card>
                        </Container>
                    </Grid>
                </Grid>
                {notificationCtx.open && <Notification/>}
                <Loading show={combinedLoading}/>
            </form>
        </Elements>
    );
};

export default PlaceOrder;