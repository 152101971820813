import React, {createContext, useEffect, useRef, useState} from "react";
import lodash from "lodash";
import {getInitials} from "../../helpers/helper";
import {LoggedInIdentity} from "../../helpers/interfaces";
import {useAuthMutation} from "../../services/identityService";
import {ENV_DEVELOPMENT} from "../../helpers/globalConst";
import {useLocation} from "react-router-dom";
import {MY_ACCOUNT} from "../../helpers/routes";
import {useAppSelector} from "../../app/hooks";

const LoggedInIdentityContext = createContext<{
    loggedInIdentity?: LoggedInIdentity,
    getLoggedInIdentity: () => void
    getLoggedInIdentityInitials: () => string | undefined
}>({
    loggedInIdentity: undefined,
    getLoggedInIdentity: () => {},
    getLoggedInIdentityInitials: () => {return undefined}
});
export default LoggedInIdentityContext;

export const LoggedInIdentityContextProvider = (props: {
    children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactPortal | null | undefined;
}) => {
    const location = useLocation();
    const [loggedInIdentity, setLoggedInIdentity] = useState<LoggedInIdentity>();
    const [auth] = useAuthMutation();
    const devEnv = useRef(false);
    const loggedInIdentityState = useAppSelector((state) => state.root.identityReducer.loggedInIdentity);

    useEffect(() => {
        const isAuth = () => {
            if (!location.pathname.includes(MY_ACCOUNT)) {
                auth({
                    userAccessToken: localStorage.getItem('accessToken'),
                    userRefreshToken: localStorage.getItem('refreshToken')
                });
            }

            // To avoid to call auth twice in dev env because of React.Strict
            if (process.env.NODE_ENV === ENV_DEVELOPMENT) devEnv.current = true;
        }

        if (!devEnv.current) isAuth();
    }, [location.pathname, auth]);

    const getLoggedInIdentity = () => {
        setLoggedInIdentity(loggedInIdentityState);
    };

    const getLoggedInIdentityInitials = () => {
        if (lodash.isEmpty(loggedInIdentity) || lodash.isNil(loggedInIdentity)) return undefined;
        return getInitials(lodash.get(loggedInIdentity, 'firstName'), lodash.get(loggedInIdentity, 'lastName'));
    };

    const value = {
        loggedInIdentity,
        getLoggedInIdentity: getLoggedInIdentity,
        getLoggedInIdentityInitials: getLoggedInIdentityInitials
    };

    return (<LoggedInIdentityContext.Provider value={value}>{props.children}</LoggedInIdentityContext.Provider>);
};