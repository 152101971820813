import React, {FormEvent, useContext, useState} from 'react';
import {AddressElement, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {PaymentMethod, StripeError} from "@stripe/stripe-js";
import Grid from "@mui/material/Grid2";
import {Card, CardContent, Container} from "@mui/material";
import lodash from "lodash";
import NotificationContext from "../../components/NotificationContext";
import Notification from "../../components/Notification";
import {useCreatePaymentIntentMutation} from "../../services/paymentService";
import {useGetProductsQuery} from "../../services/productService";
import {useAppSelector} from "../../app/hooks";
import Action from "./action";
import {getCartItemsTotal, getTotalItems} from "../../helpers/helper";
import Loading from "../Loading";

const PaymentDetails = ({setStep = (step: number) => {}, setSecret = (secret: string) => {}, setPaymentMethod = (paymentMethod: PaymentMethod) => {}}) => {
    const stripe = useStripe();
    const elements = useElements();
    const notificationCtx = useContext(NotificationContext);
    const [loading, setLoading] = useState(false);
    const [createPaymentIntent, {isLoading: createPaymentIntentLoading}] = useCreatePaymentIntentMutation();
    const cartContentState = useAppSelector((state) => state.root.cartReducer.cartContent);
    const cartItems = lodash.get(cartContentState, 'cartItems') || [];
    const {data: maybeProducts, isLoading: getProductsLoading} = useGetProductsQuery({});

    const handleError = (error: StripeError) => {
        setLoading(false);
        if (!lodash.isUndefined(error.message)) notificationCtx.showNotification(error.message, 'error');
    }

    const combinedLoading = !stripe || loading || createPaymentIntentLoading || getProductsLoading;

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        e.preventDefault();

        if (!stripe) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        if (!elements) return;

        setLoading(true);

        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit()
        if (submitError) {
            handleError(submitError);
            return;
        }

        // Create the PaymentMethod using the details collected by the Payment Element
        const {error, paymentMethod} = await stripe.createPaymentMethod({elements});


        if (error) {
            // This point is only reached if there's an immediate error when
            // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
            handleError(error);
            return;
        }

        setPaymentMethod(paymentMethod);
        // Now that you have a PaymentMethod, you can use it in the following steps to render a confirmation page or run additional validations on the server
        createPaymentIntent(paymentMethod).unwrap().then((clientSecret) => {
            setLoading(false);
            setSecret(clientSecret);
            setStep(2);
        }).catch((err) => {
            handleError({message: err} as StripeError);
        })
    };

    const handleBackToCart = () => {
        setStep(0);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid size={{ xs: 12, sm: 12, md: 8, lg: 8 }}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%'}}>
                            <CardContent>
                                <PaymentElement/>
                                <AddressElement options={{
                                    mode: 'billing', defaultValues: {
                                        address: {
                                            line1: null,
                                            line2: null,
                                            city: null,
                                            state: 'N/A',
                                            postal_code: null,
                                            country: 'US',
                                        }
                                    }
                                }}/>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%', maxHeight: {lg: 250, md: 250, sm: 250, xs: 250}}}>
                            <CardContent>
                                <Action totalItems={getTotalItems(cartItems)}
                                        cartItemsTotal={getCartItemsTotal(cartItems, maybeProducts)}
                                        disabled={combinedLoading} handleBack={handleBackToCart}/>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Grid>
            {notificationCtx.open && <Notification/>}
            <Loading show={combinedLoading}/>
        </form>
    );
};

export default PaymentDetails;