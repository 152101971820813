import React from "react";
import Grid from "@mui/material/Grid2";
import {ListItem} from "@mui/material";
import {PaymentMethod} from "@stripe/stripe-js";
import lodash from "lodash";
import Button from "@mui/material/Button";

interface PaymentMethodItemProps {
    paymentMethod?: PaymentMethod
    handleBack?: () => void
}

function PaymentMethodItem(paymentMethodItemProps: PaymentMethodItemProps) {
    const {paymentMethod, handleBack} = paymentMethodItemProps;
    return (
        <ListItem secondaryAction={
            <Button variant='contained' onClick={handleBack}>
                Change
            </Button>
        }>
            <Grid size={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
                {lodash.startCase(paymentMethod?.card?.brand) + ' ' + paymentMethod?.card?.last4}
            </Grid>
        </ListItem>
    )
}

export default PaymentMethodItem;