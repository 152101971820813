import React from "react";
import Grid from "@mui/material/Grid2";

function Total({cartItemsTotal = 0}) {
    return (
        <>
            <Grid size={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            </Grid>
            <Grid size={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                <strong>Total:</strong>
            </Grid>
            <Grid size={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                <strong>${cartItemsTotal.toFixed(2)}</strong>
            </Grid>
        </>
    )
}

export default Total;