import * as React from "react";
import {useAppSelector} from "../../../app/hooks";
import {useGetDownloadsQuery} from "../../../services/orderService";
import {DownloadsReq} from "../../../helpers/interfaces";
import Grid from "@mui/material/Grid2";
import {Card, CardContent, Container, Divider, List, ListItem} from "@mui/material";
import Header from "./header";
import Item from "./item";
import Loading from "../../../components/Loading";
import Typography from "@mui/material/Typography";

function Downloads() {
    const loggedInIdentityState = useAppSelector((state) => state.root.identityReducer.loggedInIdentity);
    const {
        data: maybeDownloads,
        isLoading: downloadsLoading
    } = useGetDownloadsQuery({customerId: loggedInIdentityState.id, page: 1, size: 50} as DownloadsReq);

    const downloads = maybeDownloads || [];
    const haveDownloads = !!downloads.length;

    return (
        <>
            <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid size={{xs: 12, sm: 12, md: 8, lg: 8}} offset={{md: 2, lg: 2}}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%'}}>
                            {!haveDownloads && <CardContent>No downloads</CardContent>}
                            {haveDownloads && <CardContent>
                                <Typography component='h1' variant='h5'>
                                    My Downloads
                                </Typography>
                                <List>
                                    <ListItem secondaryAction={<></>}>
                                        <Header/>
                                    </ListItem>
                                    <Divider/>
                                    {downloads.map((download, idx) => (
                                        <Item key={idx} idx={idx} download={download}/>
                                    ))}
                                </List>
                            </CardContent>}
                        </Card>
                    </Container>
                </Grid>
            </Grid>
            <Loading show={downloadsLoading}/>
        </>
    );
}

export default Downloads;