import React from "react";
import Grid from "@mui/material/Grid2";

function Total({orderTotal = 0}) {
    return (
        <>
            <Grid size={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            </Grid>
            <Grid size={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                <strong>Total Before Tax:</strong><br/>
                Tax Collected:<br/>
                <strong>Grand Total:</strong>
            </Grid>
            <Grid size={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                <strong>${orderTotal.toFixed(2)}</strong><br/>
                $0.00<br/>
                <strong>${orderTotal.toFixed(2)}</strong>
            </Grid>
        </>
    )
}

export default Total;