import * as React from "react";
import Grid from "@mui/material/Grid2";
import {Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Container} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";
import {useGetPublicResourceQuery} from "../../services/identityService";
import Loading from "../../components/Loading";


const Docs = () => {
    const {data: maybeDoc1, isLoading: getPublicResourceLoading} = useGetPublicResourceQuery('install_magento_ext_from_zip.html');
    const installMagentoExtDoc = maybeDoc1 || '';

    return (
        <>
            <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid size={{xs: 12, sm: 12, md: 12, lg: 12}}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%'}}>
                            <CardContent>
                                <Typography variant="h3" gutterBottom align={'center'} sx={{fontWeight: 'bold'}}>
                                    Docs & User Guides
                                </Typography>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography variant={"h5"} sx={{fontWeight: 'bold'}} align={'justify'}>
                                            How to install Magento 2 extension from a ZIP archive
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails dangerouslySetInnerHTML={{__html: installMagentoExtDoc}} />
                                </Accordion>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Grid>
            <Loading show={getPublicResourceLoading}/>
        </>
    );
}

export default Docs;