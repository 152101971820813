import * as React from "react";
import {useLocation} from "react-router-dom";
import {useGetProductsQuery} from "../../services/productService";
import lodash from "lodash";
import {getCategoryByLocation, getCategoryTitleByLocation} from "../../helpers/helper";
import {Product} from "../../helpers/interfaces";
import ProductCard from "../../components/ProductCard";
import {ROOT_CATEGORY} from "../../helpers/globalConst";
import Grid from "@mui/material/Grid2";
import {CategoryTitleTypography} from "../../particles/CategoryTitleTypography";
import Loading from "../../components/Loading";

function Store() {
    const location = useLocation();
    const {data: maybeProducts, isLoading} = useGetProductsQuery({});
    const getProducts = () => {
        const products = maybeProducts || [];
        const category = getCategoryByLocation(location.pathname);
        return category === ROOT_CATEGORY ? products : lodash.filter(products, {productItems: [{category: category}]}) as Product[]
    }

    return (
        <>
            <CategoryTitleTypography>{getCategoryTitleByLocation(location.pathname)}</CategoryTitleTypography>
            <Grid container size={{ xs: 12, sm: 12, md: 12, lg: 12 }} columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                {getProducts().map((product) => {
                    return <Grid key={product.id} size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                        <ProductCard key={product.id} product={product}/>
                    </Grid>
                })}
            </Grid>
            <Loading show={isLoading}/>
        </>
    );
}

export default Store;