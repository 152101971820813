import {Mutex} from "async-mutex";
import type {BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta} from "@reduxjs/toolkit/query";
import {fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {
    deleteTokens,
    getIdentityData,
    saveAccessToken,
    saveRefreshToken,
    setHeaderAccessToken,
    setHeaderRefreshToken
} from "../helpers/helper";
import {OPEN_END_POINTS} from "../helpers/apiRoutes";
import {UNAUTHORIZED} from "../helpers/globalConst";
import lodash from "lodash";
import {ApiResponse} from "../helpers/interfaces";
import {createApi} from "@reduxjs/toolkit/query/react";
import {removeIdentity, saveIdentity} from "../app/store/identitySlice";
import {Action} from "@reduxjs/toolkit";

const mutex = new Mutex();
const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta> = fetchBaseQuery(
    {
        baseUrl: '/api',
        prepareHeaders: (headers: Headers) => {
            return setHeaderAccessToken(headers)
        }
    });

const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta> =
    async (args, api, extraOptions) => {
        const dispatch = async (action: Action) => {
            return api.dispatch(action)
        };
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        let result = await baseQuery(args, api, extraOptions);
        if (!result.error || OPEN_END_POINTS.includes(api.endpoint)) {
            return result;
        }

        // checking whether the mutex is locked
        if (mutex.isLocked()) {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            return baseQuery(args, api, extraOptions);
        }

        try {
            if (result.error.status !== UNAUTHORIZED) {
                return result;
            }

            const refreshResult = await fetchBaseQuery(
                {
                    baseUrl: '/api',
                    prepareHeaders: (headers) => {
                        return setHeaderRefreshToken(setHeaderAccessToken(headers))
                    }
                })('/renewCustomerTokens', api, extraOptions);

            if (!refreshResult.data) {
                await deleteTokens();
                await dispatch(removeIdentity());
                return result;
            }

            const headers = lodash.get(refreshResult.meta, 'response.headers');
            if (headers === undefined || lodash.isNull(headers)) {
                await deleteTokens();
                await dispatch(removeIdentity());
                return result;
            }

            saveAccessToken(headers);
            saveRefreshToken(headers);
            const identityData = getIdentityData(refreshResult.data as ApiResponse);
            if (!lodash.isUndefined(identityData)) await dispatch(saveIdentity(identityData));

            // retry the initial query
            return await baseQuery(args, api, extraOptions);
        } finally {
            // release must be called once the mutex should be released again.
            mutex.release();
        }
    }

export const baseApi = createApi({
    baseQuery: baseQueryWithReAuth,
    endpoints: () => ({})
});

export const baseApiReducer = baseApi.reducer;