import React, {useState} from "react";
import Grid from "@mui/material/Grid2";
import {ListItem} from "@mui/material";
import lodash from "lodash";
import {Order, PaymentMethod} from "../../../helpers/interfaces";
import Button from "@mui/material/Button";
import ViewInvoiceDialog from "../../../components/ViewInvoiceDialog";

interface PaymentMethodItemProps {
    paymentMethod?: PaymentMethod,
    order?: Order
}

function PaymentMethodItem(paymentMethodItemProps: PaymentMethodItemProps) {
    const {paymentMethod, order} = paymentMethodItemProps;
    const [openViewInvoiceDialog, setOpenViewInvoiceDialog] = useState(false);

    const handleOpenViewInvoiceDialog = () => {
        setOpenViewInvoiceDialog(true);
    };

    const handleCloseViewInvoiceDialog = () => {
        setOpenViewInvoiceDialog(false);
    };

    return (
        <>
            <ListItem secondaryAction={
                <Button onClick={handleOpenViewInvoiceDialog}>
                    VIEW INVOICE
                </Button>}>
                <Grid size={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
                    {lodash.startCase(paymentMethod?.brand) + ' ' + paymentMethod?.last4}
                </Grid>
            </ListItem>
            <ViewInvoiceDialog show={openViewInvoiceDialog} handleClose={handleCloseViewInvoiceDialog} order={order} paymentMethod={paymentMethod}/>
        </>
    )
}

export default PaymentMethodItem;