import * as React from 'react';
import {CSSObject, styled, Theme, useTheme} from '@mui/material/styles';
import MuiDrawer, {DrawerProps as MuiDrawerProps} from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ProfileSettingsIcon from '@mui/icons-material/AccountBox';
import MyDownloadsIcon from '@mui/icons-material/Download';
import MyOrdersIcon from '@mui/icons-material/Ballot';
//import PaymentMethodsIcon from '@mui/icons-material/Payment';
import {Tooltip} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {DOWNLOADS, MY_ACCOUNT, PROFILE} from "../../helpers/routes";
import {useContext, useEffect} from "react";
import LoggedInIdentityContext from "../LoggedInIdentityContext";
import lodash from "lodash";

interface DrawerProps extends MuiDrawerProps {
    drawerWidth?: number
}

const openedMixin = (theme: Theme, drawerWidth?: number): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth'
})<DrawerProps>(({theme, open, drawerWidth}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme, drawerWidth),
            '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MyAccountDrawer({open = false, drawerWidth = 0, handleDrawerClose = () => {}}) {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const loggedInIdentityCtx = useContext(LoggedInIdentityContext);
    useEffect(() => {
        loggedInIdentityCtx.getLoggedInIdentity();
        // eslint-disable-next-line
    }, [])

    const hasLoggedInIdentity = !(lodash.isEmpty(loggedInIdentityCtx.loggedInIdentity) || lodash.isNil(loggedInIdentityCtx.loggedInIdentity));

    if (!hasLoggedInIdentity) return null;
    return (
        <Drawer variant="permanent" open={open} drawerWidth={drawerWidth}>
            <DrawerHeader sx={{minHeight: {lg: 84, md: 84, sm: 75, xs: 61}}} /*TODO*/>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                </IconButton>
            </DrawerHeader>
            <Divider/>
            <List>
                <ListItem key={1} disablePadding sx={{display: 'block'}} onClick={() => navigate(MY_ACCOUNT)}>
                    <ListItemButton
                        selected={location.pathname === MY_ACCOUNT}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <Tooltip title="My orders" placement={"right"}><MyOrdersIcon/></Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={'My Orders'} sx={{opacity: open ? 1 : 0}}/>
                    </ListItemButton>
                </ListItem>
                <ListItem key={2} disablePadding sx={{display: 'block'}} onClick={() => navigate(MY_ACCOUNT + DOWNLOADS)}>
                    <ListItemButton
                        selected={location.pathname.includes(DOWNLOADS)}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                      <Tooltip title="My downloads" placement={"right"}><MyDownloadsIcon/></Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={'My downloads'} sx={{opacity: open ? 1 : 0}}/>
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider/>
            <List>
                {/*<ListItem key={1} disablePadding sx={{display: 'block'}}>*/}
                {/*    <ListItemButton*/}
                {/*        sx={{*/}
                {/*            minHeight: 48,*/}
                {/*            justifyContent: open ? 'initial' : 'center',*/}
                {/*            px: 2.5,*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <ListItemIcon*/}
                {/*            sx={{*/}
                {/*                minWidth: 0,*/}
                {/*                mr: open ? 3 : 'auto',*/}
                {/*                justifyContent: 'center',*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <Tooltip title="Payment methods" placement={"right"}><PaymentMethodsIcon/></Tooltip>*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary={'Payment methods'} sx={{opacity: open ? 1 : 0}}/>*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}
                <ListItem key={2} disablePadding sx={{display: 'block'}} onClick={() => navigate(MY_ACCOUNT + PROFILE)}>
                    <ListItemButton
                        selected={location.pathname.includes(PROFILE)}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <Tooltip title="Profile settings" placement={"right"}><ProfileSettingsIcon/></Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={'Profile settings'} sx={{opacity: open ? 1 : 0}}/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    );
}