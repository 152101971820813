import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid2";
import {Box, Card, CardContent, Checkbox, Container, IconButton, InputAdornment} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {profileSchema} from "../../../helpers/helper";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import TextField from "@mui/material/TextField";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Button from "@mui/material/Button";
import 'react-international-phone/style.css';
import {MuiPhone} from "../../../particles/MuiPhone";
import {useProfileMutation} from "../../../services/identityService";
import NotificationContext from "../../../components/NotificationContext";
import Notification from "../../../components/Notification";
import Loading from "../../../components/Loading";
import {
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PROFILE_FAILED,
    UPDATE_PROFILE_SUCCESS,
    WRONG_CURRENT_PASSWORD
} from "../../../helpers/notifications";
import {saveIdentity} from "../../../app/store/identitySlice";

function Profile() {
    const dispatch = useAppDispatch();
    const [phone, setPhone] = useState('');
    const loggedInIdentityState = useAppSelector((state) => state.root.identityReducer.loggedInIdentity);
    const notificationCtx = useContext(NotificationContext);
    const {
        register,
        handleSubmit, formState: {errors},
        clearErrors
    } =
        useForm(
            {
                resolver: yupResolver(profileSchema),
                defaultValues: {
                    email: loggedInIdentityState.email,
                    firstName: loggedInIdentityState.firstName,
                    lastName: loggedInIdentityState.lastName,
                    company: loggedInIdentityState.company,
                    subscribed: loggedInIdentityState.subscribed
                }
            });
    const [showPassword, setShowPassword] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [changePasswordMode, setChangePasswordMode] = useState(false);
    const [updateProfile, {isLoading}] = useProfileMutation();

    useEffect(() => {
        setPhone(loggedInIdentityState.phone || '')
    }, [loggedInIdentityState]);

    const onSubmit = (data: any) => {
        data.phone = phone;
        updateProfile(data)
            .unwrap()
            .then((loggedInIdentity) => {
                dispatch(saveIdentity(loggedInIdentity))
                notificationCtx.showNotification(loggedInIdentity.message === "customer_profile_update_succeeded"
                    ? UPDATE_PROFILE_SUCCESS : UPDATE_PASSWORD_SUCCESS, 'success')
            })
            .catch((err: string) => {
                notificationCtx.showNotification(err === 'current_password_invalid'
                    ? UPDATE_PROFILE_FAILED : WRONG_CURRENT_PASSWORD, 'error')
            })
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleEdit = () => {
        setEditMode(true);
    }

    const handleChangePassword = () => {
        setChangePasswordMode(true);
    }

    const handleCancelEdit = () => {
        setEditMode(false);
        setChangePasswordMode(false);
        clearErrors();
    }

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

    const handleMouseDownNewPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <>
            <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid size={{xs: 12, sm: 12, md: 8, lg: 8}} offset={{md: 2, lg: 2}}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%'}}>
                            <CardContent>
                                <Typography component='h1' variant='h5'>
                                    Profile Settings
                                </Typography>
                                <Box component='form' noValidate onSubmit={handleSubmit(onSubmit)} sx={{mt: 3}}>
                                    <Grid container spacing={2}>
                                        <Grid size={{xs: 12, sm: 6}}>
                                            <TextField
                                                {...register('email', {required: true})}
                                                fullWidth
                                                id='email'
                                                label='Email Address'
                                                autoComplete='email'
                                                error={!!errors.email}
                                                variant='outlined'
                                                helperText={errors.email?.message}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid size={{xs: 12, sm: 6}}></Grid>
                                        <Grid size={{xs: 12, sm: 6}}>
                                            <TextField
                                                {...register('firstName', {required: true})}
                                                autoComplete='given-name'
                                                required
                                                fullWidth
                                                id='firstName'
                                                label='First Name'
                                                autoFocus
                                                error={!!errors.firstName}
                                                variant='outlined'
                                                helperText={errors.firstName?.message}
                                                disabled={!editMode}
                                            />
                                        </Grid>
                                        <Grid size={{xs: 12, sm: 6}}>
                                            <TextField
                                                {...register('lastName', {required: true})}
                                                required
                                                fullWidth
                                                id='lastName'
                                                label='Last Name'
                                                autoComplete='family-name'
                                                error={!!errors.lastName}
                                                variant='outlined'
                                                helperText={errors.lastName?.message}
                                                disabled={!editMode}
                                            />
                                        </Grid>
                                        <Grid size={{xs: 12, sm: 6}}>
                                            <MuiPhone
                                                {...register('phone', {required: false})}
                                                fullWidth
                                                id='phone'
                                                label='Phone (optional)'
                                                error={!!errors.phone}
                                                variant='outlined'
                                                helperText={errors.phone?.message}
                                                disabled={!editMode}
                                                value={phone}
                                                onChange={(phone) => setPhone(phone)}
                                            />
                                        </Grid>
                                        <Grid size={{xs: 12, sm: 6}}>
                                            <TextField
                                                {...register('company', {required: false})}
                                                fullWidth
                                                id='company'
                                                label='Company (optional)'
                                                error={!!errors.company}
                                                variant='outlined'
                                                helperText={errors.company?.message}
                                                disabled={!editMode}
                                            />
                                        </Grid>
                                        <Grid size={{xs: 12, sm: 6}}>
                                            <Typography>
                                                Would you like to receive News & updates via e-mail and/or text message?
                                            </Typography>
                                        </Grid>
                                        <Grid size={{xs: 12, sm: 6}}>
                                            <Checkbox
                                                {...register('subscribed', {required: false})}
                                                id='subscribed'
                                                disabled={!editMode}
                                                defaultChecked={loggedInIdentityState.subscribed}
                                            />
                                        </Grid>
                                        <Grid size={{xs: 12, sm: 6}}>
                                            <TextField
                                                {...register('currentPassword', {required: true})}
                                                required
                                                fullWidth
                                                label='Current password'
                                                type={showPassword ? 'text' : 'password'}
                                                id='password'
                                                autoComplete='new-password'
                                                error={!!errors.currentPassword}
                                                variant='outlined'
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }}
                                                helperText={errors.currentPassword?.message}
                                                disabled={!editMode && !changePasswordMode}
                                            />
                                        </Grid>
                                        <Grid size={{xs: 12, sm: 6}}>
                                            {!editMode && !changePasswordMode &&
                                                <Button type='button' size='large' fullWidth variant='contained'
                                                        onClick={handleChangePassword} sx={{mt: 1}}>
                                                    Change password
                                                </Button>}
                                        </Grid>
                                        {changePasswordMode && <Grid size={{xs: 12, sm: 6}}>
                                            <TextField
                                                {...register('newPassword', {required: true})}
                                                required
                                                fullWidth
                                                label='New Password'
                                                type={showNewPassword ? 'text' : 'password'}
                                                id='new-password'
                                                autoComplete='new-password'
                                                error={!!errors.newPassword}
                                                variant='filled'
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle new-password visibility"
                                                            onClick={handleClickShowNewPassword}
                                                            onMouseDown={handleMouseDownNewPassword}
                                                            edge="end"
                                                        >
                                                            {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }}
                                                helperText={errors.newPassword?.message}
                                            />
                                        </Grid>}
                                        {changePasswordMode && <Grid size={{xs: 12, sm: 6}}>
                                            <TextField
                                                {...register('confirmPassword', {required: true})}
                                                required
                                                fullWidth
                                                label='Confirm Password'
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                id='confirm-password'
                                                autoComplete='new-password'
                                                error={!!errors.confirmPassword}
                                                variant='filled'
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle confirm-password visibility"
                                                            onClick={handleClickShowConfirmPassword}
                                                            onMouseDown={handleMouseDownConfirmPassword}
                                                            edge="end"
                                                        >
                                                            {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }}
                                                helperText={errors.confirmPassword?.message}
                                            />
                                        </Grid>}
                                        <Grid size={{xs: 12, sm: 6}}>
                                            {!editMode && !changePasswordMode ?
                                                <Button type='button' fullWidth variant='contained' onClick={handleEdit}
                                                        size='large' sx={{mt: 1}}>
                                                    Edit
                                                </Button> :
                                                <Button type='submit' fullWidth variant='contained' size='large'
                                                        sx={{mt: 1}}>
                                                    Update
                                                </Button>}
                                        </Grid>
                                        <Grid size={{xs: 12, sm: 6}}>
                                            {(editMode || changePasswordMode) &&
                                                <Button type='button' fullWidth variant='outlined'
                                                        onClick={handleCancelEdit} size='large' sx={{mt: 1}}>
                                                    Cancel
                                                </Button>}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Grid>
            {notificationCtx.open && <Notification/>}
            <Loading show={isLoading}/>
        </>
    );
}

export default Profile;