import React, {Fragment} from "react";
import Grid from "@mui/material/Grid2";
import {Divider, Link, ListItem} from "@mui/material";
import {Order} from "../../helpers/interfaces";
import {Link as RouterLink} from "react-router-dom";
import {MY_ACCOUNT, ORDER} from "../../helpers/routes";
import {getOrderDate} from "../../helpers/helper";

interface ItemProps {
    idx: number,
    order: Order
}

function Item(itemProps: ItemProps) {
    const {idx, order} = itemProps;

    return (
        <Fragment key={idx}><ListItem secondaryAction={
            <Link color="inherit" underline="none" component={RouterLink} to={`${MY_ACCOUNT + ORDER}/${order.id}`}>
                ORDER DETAILS
            </Link>
        }>
            <Grid size={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
                {order.id}
            </Grid>
            <Grid size={{ xs: 2, sm: 2, md: 2, lg: 5 }}>
                {getOrderDate(order.createdAt || '')}
            </Grid>
            <Grid size={{ xs: 2, sm: 2, md: 2, lg: 4 }}>
                ${order.total?.toFixed(2)}
            </Grid>
        </ListItem>
            <Divider/>
        </Fragment>
    )
}

export default Item;