import React from "react";
import Grid from "@mui/material/Grid2";

function PaymentMethodHeader({activeStep = 0}) {
    return (
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
            <strong>Payment Method</strong>
        </Grid>
    )
}

export default PaymentMethodHeader;