import * as React from "react";
import Grid from "@mui/material/Grid2";
import {Card, CardContent, Container} from "@mui/material";
import {useGetPublicResourceQuery} from "../../services/identityService";
import Loading from "../../components/Loading";

const PrivacyPolicy = () => {
    const {data: maybeDoc, isLoading: getPublicResourceLoading} = useGetPublicResourceQuery('privacy_poliicy.html');
    const doc = maybeDoc || '';
    return (
        <>
            <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid size={{xs: 12, sm: 12, md: 12, lg: 12}}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%'}}>
                            <CardContent dangerouslySetInnerHTML={{__html: doc}}/>
                        </Card>
                    </Container>
                </Grid>
            </Grid>
            <Loading show={getPublicResourceLoading}/>
        </>
    );
}

export default PrivacyPolicy;