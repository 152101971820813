import * as React from "react";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useLazyGetProductQuery} from "../../services/productService";
import {Product as ProductData} from "../../helpers/interfaces";
import lodash from "lodash";
import {NOT_FOUND} from "../../helpers/routes";
import Grid from "@mui/material/Grid2";
import {Card, CardContent, Container} from "@mui/material";
import {PRODUCT_IMAGE_BASE_URL} from "../../helpers/apiRoutes";
import Carousel from "react-material-ui-carousel";
import Button from "@mui/material/Button";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useAddCartItemMutation} from "../../services/cartService";
import {addToCart} from "../../app/store/cartSlice";
import {ADD_TO_CART, MAIN_PRODUCT_IMAGE_ORDER} from "../../helpers/globalConst";
import Loading from "../../components/Loading";

function Product() {
    const loggedInIdentityState = useAppSelector((state) => state.root.identityReducer.loggedInIdentity);
    const hasLoggedInIdentity = !(lodash.isEmpty(loggedInIdentityState) || lodash.isNil(loggedInIdentityState));
    const location = useLocation();
    const navigate = useNavigate();
    const {id} = useParams();
    const [getProductById, result] = useLazyGetProductQuery();
    const [product, setProduct] = useState(lodash.get(location, 'state.product', {}) as ProductData || {});
    const dispatch = useAppDispatch();
    const [addCartItem, {isLoading: addCartItemLoading}] = useAddCartItemMutation();
    const price = product.productItems?.reduce(
        (acc, productItem) => acc + (productItem.price || 0.0), 0.00) || 0.0;

    useEffect(() => {
        if ((lodash.isEmpty(product) || lodash.isUndefined(product)) && !lodash.isUndefined(id)) getProductById(id, true);
        if (result.isSuccess && !(lodash.isNil(result.data) || lodash.isEmpty(result.data))) {
            setProduct(result.data as ProductData);
        }
    }, [product, id, getProductById, result.isSuccess, result.data]);

    const productNotFound = result.isError || (result.isSuccess && (lodash.isNil(result.data) || lodash.isEmpty(result.data)));
    if (productNotFound) navigate(NOT_FOUND);

    const handleAddToCart = () => {
        dispatch(addToCart({productId: product.id, qty: 1}));
        if (hasLoggedInIdentity) addCartItem({productId: product.id, qty: 1}); // TODO cover error case
    };

    return (
        <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
            <Grid size={{ xs: 12, sm: 12, md: 8, lg: 8 }}>
                <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                    <Card variant="outlined" sx={{height: '100%'}}>
                        <CardContent>
                            <Carousel sx={{minHeight: {lg: 418, md: 418, sm: 418, xs: 250}}}>
                                {
                                    product.productImages?.filter(img => img.order !== MAIN_PRODUCT_IMAGE_ORDER)
                                        .map((p, i) => {
                                        return <img key={i} alt={product.description} width={'100%'}
                                                    src={`${PRODUCT_IMAGE_BASE_URL + p.location}`}/>
                                    })
                                }
                            </Carousel>
                            <Typography gutterBottom variant="h5" sx={{fontSize: {lg: 21, md: 21, sm: 21, xs: 18}}}>
                                {product.description}
                            </Typography>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                    <Card variant="outlined" sx={{height: '100%', maxHeight: {lg: 200, md: 200, sm: 200, xs: 200}}}>
                        <CardContent>
                            <Typography variant="h4" gutterBottom sx={{fontWeight: 'bold', float: 'right'}}>
                                {`$${price.toFixed(2)}`}
                            </Typography>
                            <Typography variant="h6" gutterBottom sx={{fontWeight: 'bold'}}>
                                {product.description}
                            </Typography>
                            <Typography gutterBottom sx={{fontWeight: 'bold'}}>
                                Edition: Community
                            </Typography>
                            <Button
                                fullWidth
                                variant='contained'
                                sx={{mt: 3, mb: 2}}
                                onClick={handleAddToCart}
                            >
                                {ADD_TO_CART}
                            </Button>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 8, lg: 8 }}>
                <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                    <Card variant="outlined" sx={{height: '100%'}}>
                        <CardContent>
                            <Container component='div' sx={{display: 'flex', justifyContent: 'center'}}>
                                {
                                    product.productItems?.map((p, i) => {
                                        return <Typography variant={'body1'} gutterBottom
                                                           dangerouslySetInnerHTML={{__html: p.productItemProperties?.description || ''}}></Typography>
                                    })
                                }
                            </Container>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Loading show={addCartItemLoading}/>
        </Grid>
    );
}

export default Product;