import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Box, Card, CardContent, Container, IconButton, InputAdornment, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import {useForm} from "react-hook-form";
import {useResetPasswordMutation, useValidateAuthTokenMutation} from "../../services/identityService";
import NotificationContext from "../NotificationContext";
import Notification from "../Notification";
import Loading from "../Loading";
import {useLocation, useNavigate} from "react-router-dom";
import AvatarLockOutlinedIcon from "../../particles/AvatarLockOutlinedIcon";
import TextField from "@mui/material/TextField";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {SIGN_IN} from "../../helpers/routes";
import {yupResolver} from "@hookform/resolvers/yup";
import {resetPasswordSchema} from "../../helpers/helper";
import {PASSWORD_RESET_FAILED, PASSWORD_RESET_LINK_INVALID, PASSWORD_RESET_SUCCESS} from "../../helpers/notifications";
import lodash from "lodash";

function ResetPasswordCard() {
    const location = useLocation();
    const navigate = useNavigate();
    const notificationCtx = useContext(NotificationContext);
    const {
        register, handleSubmit,
        formState: {errors}
    } = useForm({resolver: yupResolver(resetPasswordSchema)});
    const [resetPassword, resetPasswordResult] = useResetPasswordMutation();
    const [validateAuthToken, validateAuthTokenResult] = useValidateAuthTokenMutation();
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const authToken = lodash.last(location.pathname.split('/'));

    useEffect(() => {
        validateAuthToken(
            {
                authToken: !lodash.isUndefined(authToken) ? authToken : ''
            }
        ).unwrap()
            .catch(
                () => {
                    navigate(SIGN_IN);
                    notificationCtx.showNotification(PASSWORD_RESET_LINK_INVALID, 'error');
                }
            );
    }, [authToken, navigate, notificationCtx, validateAuthToken])

    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

    const handleMouseDownNewPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const onSubmit = (data: any) => {
        let payload = {
            newPassword: data.newPassword,
            authToken: !lodash.isUndefined(authToken) ? authToken : ''
        }

        resetPassword(payload)
            .unwrap()
            .then(() => {
                navigate(SIGN_IN);
                notificationCtx.showNotification(PASSWORD_RESET_SUCCESS, 'success');
            })
            .catch(
                () => {
                    navigate(SIGN_IN);
                    notificationCtx.showNotification(PASSWORD_RESET_FAILED, 'error');
                }
            );
    }

    return (
        <>
            <Container sx={{pt: 2, pb: 3}} maxWidth='xs'>
                <Card variant="outlined">
                    <CardContent>
                        <Container component='div' sx={{display: 'flex', justifyContent: 'center'}}>
                            <AvatarLockOutlinedIcon/>
                        </Container>
                        <Typography component='h1' variant='h5' sx={{textAlign: 'center'}}>
                            Reset password
                        </Typography>
                        <Box component='form' noValidate onSubmit={handleSubmit(onSubmit)} sx={{mt: 3}}>
                            <Grid container spacing={2}>
                                <Grid size={{ xs: 12 }}>
                                    <TextField
                                        {...register('newPassword', {required: true})}
                                        required
                                        fullWidth
                                        label='New Password'
                                        type={showNewPassword ? 'text' : 'password'}
                                        id='new-password'
                                        autoComplete='new-password'
                                        error={!!errors.newPassword}
                                        variant='filled'
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle new-password visibility"
                                                    onClick={handleClickShowNewPassword}
                                                    onMouseDown={handleMouseDownNewPassword}
                                                    edge="end"
                                                >
                                                    {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                        helperText={errors.newPassword?.message}
                                    />
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <TextField
                                        {...register('confirmPassword', {required: true})}
                                        required
                                        fullWidth
                                        label='Confirm Password'
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        id='confirm-password'
                                        autoComplete='new-password'
                                        error={!!errors.confirmPassword}
                                        variant='filled'
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle confirm-password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDownConfirmPassword}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                        helperText={errors.confirmPassword?.message}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                sx={{mt: 3, mb: 2}}
                            >
                                Reset
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {notificationCtx.open && <Notification/>}
            <Loading show={resetPasswordResult.isLoading || validateAuthTokenResult.isLoading}/>
        </>
    );
}

export default ResetPasswordCard;